@font-face {
  font-family: fortnite;
  src: url(fonts/BurbankBigRegular-Black.woff) format("woff") 
}

@font-face {
  font-family: fortnite-cyrillic;
  src: url(fonts/BurbankRussian.woff) format("woff") 
}

@font-face {
  font-family: "HeadingNow67Extrabold";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('fonts/HeadingNow/HeadingNow67Extrabold_normal_normal.woff2') format('woff2'), url('fonts/HeadingNow/HeadingNow67Extrabold_normal_normal.woff') format('woff');
}

a {
  color: inherit;
  text-decoration: none;
}

html, body {
  overflow: auto;
}

html{
  height:100%;
}

body {
  margin: 0;
  background-image: url(assets/landing-page-bg-3.png);
  background-repeat:no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-color: black;
  height:100%;
}

#root{
  height:100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.russian{
  font-family: fortnite-cyrillic, HeadingNow67Extrabold, sans-serif;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.wrapper{
  display: grid;
  grid-template-columns: max-content 10% max-content;
  grid-template-rows: max-content 40px;
  grid-template-areas: 
    "image  . content"
    "image . footer";
  justify-content: center;
  align-content: end;
  overflow: auto;
  position: relative;
  flex-grow: 1;
}

.header {
  background: black;
  color: white;
  font-family: "Open Sans";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: end;
  height: 40px;
  align-items: stretch;
}

.header .logo {
  max-height: 20px;
  margin: auto auto auto 25px;
}

.header .username {
  color: black;
  background: #FFE404;
  font-size: 14px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.content{
  font-family: HeadingNow67Extrabold, fortnite, Noto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: start;
  grid-area: content;
  padding: 1em 1em 1em 0;
  width: 658px;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: white;
}

.heroImg{
  grid-area: image;
  width: auto;
  height: 90vh;
  object-fit: cover;
  justify-self: end;
  align-self: end;
}


button{
  font-family: inherit;
  cursor: pointer;
}

.ctaWrapper {
  grid-area: cta;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-wrapper{
  background: url(assets/button.svg) no-repeat;
  background-size: cover;
  background-position: center;
  padding: 40px 20px 20px 20px;
  border: none;
  color: black;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 47px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  min-width: 250px;
}

.content .logo {
  max-width: 522px;
  width:100%;
  grid-area: logo;
  margin-top: 40px;
}

.subtitle {
  color: white;
  max-width: 411px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 110%;
  transform: matrix(0.97, 0, -0.24, 1, 0, 0);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: 'titl' on;
  margin: 0.5em 0 0;
}

.instructionWrapper{
  grid-area: instruction;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.1;
}

.instructionBox{
  background: #A80DB6;
  border: 6px solid #A80DB6;
  box-sizing: border-box;
  color: white;
  position: relative;
  margin: 30px 0 0 20px;
  padding: 10px 100px;
}

.instructionBox:last-of-type {
  margin-bottom: 20px;
}

.completed{
  /*background: linear-gradient(90.33deg, #10255C -2.89%, #625FFF 117.32%);*/
  background: #0C1152;
  border-color: #0C1152;
}

.instructionNumberBox{
  position: absolute;
  left: -20px;
  top: -20px;
  background: url(assets/number.svg) no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 100%;
  height: 70px;
  width: 80px;
  grid-template-columns: 1.5fr min-content 1fr;
  grid-template-rows: 2fr min-content 1fr;
  grid-template-areas: 
    ". . ."
    ". number ."
    ". . .";
}

.progressBar{
  background: #00000099;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 20px;
  border-radius: 5px;
  overflow: hidden;
  margin: 15px 0;
}

.progressBar > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #90ca46;
}

.instructionSub {
  font-size: 18px;
  margin-bottom: 5px;
}

.instructionSub.thin {
  font-family: "Open Sans";
  text-transform: uppercase;
  font-weight: normal;
  font-size: 11px;
  color: #ffffff99;
}

.downloadLink{
  text-decoration: underline;
}

.instructionNumber{
  grid-area: number;
}

.instruction{
  grid-area: text;
}

.instruction .reward {
  position: absolute;
  max-width: 300px;
  max-height: 300px;
  display: none;
  bottom: 80px;
  background: rgba(0,0,0,0.5);
  border-radius: 40px;
  left: 50%;
  margin-left: -150px;
}

.instruction:hover .reward {
  display: block;
}

.StyledFooter{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.8);
  margin: auto;
  width: 100%;
  grid-area: footer;
  margin-top: 20px;
  align-self: end;
  justify-self: center;
  text-transform: none;
}

.StyledFooterLink{
  margin-left: 19px;
  color: #4678FF;
}

.deleteSpan{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  grid-area: cta;
  justify-self: center;
  cursor: pointer;
}

.notes {
  grid-area: notes;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 12px;
  text-transform: none;
  font-weight: normal;
  max-width: 600px;
  line-height: 140%;
  letter-spacing: 1.25px;
  color: rgba(255, 255, 255, 0.8);
  justify-self: center;
}

.notes p {
  margin-bottom: 10px;
}

  @media only screen and (max-width: 900px)  {
    .wrapper{
      align-content: initial;
      justify-items: center;
    }

    .content{
      margin: 0;
      margin-top: 10%;
      padding: 0;
      font-size: 20px;
      line-height: 110%;
      letter-spacing: 0.5px;
    }

    .heroImg{
      width: 100vw;
      height: auto;
      max-width: 300px;
      justify-self: center;
    }

    .link-wrapper{
      margin: 20px 0;
      width: 300px;
      font-size: 24px;
      line-height: 140%;
      letter-spacing: 0.5px;
    }

    .deleteSpan{
      margin: 20px 0;
    }

    .content .logo {
      max-width: 400px;
      width:90%;
      margin-bottom: 35px;
    }

    .instructionWrapper{
      font-size: 20px;
      line-height: 110%;
    }

    .wrapper{
      font-size: 20px;
      line-height: 110%;
      letter-spacing: 0.5px;
      grid-template-columns: 1fr;
      grid-template-rows: max-content;
      grid-template-areas: 
        "content"
        "image"
        "footer";
    }

    .StyledFooter{
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-self: center;
      background-color: #331869;
      margin: 0;
      padding-top:20px;
    }

  }

@media only screen and (max-width: 1330px)  {
    .StyledFooter{
      flex-direction: column;
      align-items: center;
      position: static;
    }

    .StyledFooterSpan{
      margin: 12px 0 20px 0;
    }
}

@media only screen and (max-height: 930px)  {
  .wrapper{
    align-content: initial;
  }
}